import React from 'react'
import { Container, Box, Flex } from 'theme-ui'
import { FooterLogo } from './Footer.Logo'
import { FooterMenu } from './Footer.Menu'
import { FooterSocial } from './Footer.Social'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`,
    mt: [5, 6]
  }
}

export const Footer = () => (
  <Box sx={styles.wrapper}>
    <Container variant='compact' sx={styles.container}>
      {/* <Flex variant='layout.footer'> */}
      <div
        style={{
          display: 'flex',
          marginTop: '30px',
          marginBottom: '20px'
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center'
          }}
        >
          <FooterLogo />
        </Box>
        {/* <FooterMenu /> */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center'
          }}
        >
          <FooterSocial />
        </Box>
      </div>
      {/* </Flex> */}
    </Container>
  </Box>
)
